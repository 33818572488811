<template>
    <section>
        <v-row>
            <v-col v-if="$vuetify.breakpoint.smAndDown ? ( selected ? false : true) : true" :cols="$vuetify.breakpoint.smAndDown ? '12' : (selected ? '4' : '12')">
                <v-card class="custom-border border pa-2">
                    <v-card-text>
                        <section class="d-flex justify-end">
                            <v-menu v-model="menu" :close-on-content-click="false" offset-y left transition="slide-y-transition">
                                <template v-slot:activator="{ on }">
                                    <v-btn text v-on="on" dense  >
                                        Filter <v-icon small right>mdi-filter</v-icon>
                                    </v-btn>
                                </template>
                                <v-card class="pa-3" width="250">
                                    <label class="caption">CATEGORY</label>
                                    <v-select
                                        :items="category"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        v-model="filter.category"
                                        item-text="text"
                                        item-value="id"
                                        class="general-custom-field poppins mb-3 f12"
                                    />
                                    <label class="caption">STATUS</label>
                                    <v-select
                                        :items="ticketStatus"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        v-model="filter.status"
                                        item-text="text"
                                        item-value="id"
                                        class="general-custom-field poppins mb-3 f12"
                                        width="100"
                                    />
                                    <v-btn small color="primary" block dense class="mt-2" @click="getTickets()">
                                        APPLY
                                    </v-btn>    
                                </v-card>
                            </v-menu>
                        </section>
                        <v-divider class="my-3"/>
                        <v-progress-linear
                            v-if="loading"
                                indeterminate
                                color="primary"
                                rounded
                                height="4"
                                class="mb-3"
                        ></v-progress-linear>
                        <div v-else-if="!loading && tickets.length === 0" class="poppins fw600 f13 secondary-4--text font-italic"> No current tickets. </div>
                        <!-- <v-tabs show-arrows v-model="tab" slider-color="primary" role="tablist" background-color="transparent">
                            <v-tab v-for="tab in tabs" :key="tab.id" class="poppins text-wrap rounded" active-class=" primary--text" >
                                <v-badge
                                    v-if="tab.count > 0"
                                    overlap
                                    inline
                                    bordered
                                    :color="tab.count === 0 ? 'transparent' : 'primary'"
                                    :content="tab.count"
                                    :offset-x="15"
                                    style="'text'">
                                    {{  tab.text }}
                                </v-badge>
                                <span v-else>
                                    {{  tab.text }}
                                </span>
                            </v-tab>
                        </v-tabs> -->
                        <v-card outlined v-for="ticket in tickets" :key="ticket.id" class=" hover:cursor-pointer mb-3" :elevation="selected ? (selected.id === ticket.id ? '1' : '') : ''" @click="selected = ticket">
                           <!--  <section :class="$vuetify.breakpoint.smAndDown ? 'd-flex flex-column' : 'd-flex flex-row justify-space-between'"> -->
                            <section class='d-flex flex-row justify-space-between'>
                                <v-card-text class="poppins fw500 mt-0" order="2">
                                    <section class="poppins">
                                        <span class="f9">
                                            TICKET NO.
                                        </span>
                                        <h2 class="primary--text fw500">
                                            EDU-{{ ticket.id }}
                                        </h2>
                                    </section> 
                                </v-card-text>
                                <v-card-text class="d-flex flex-column text-uppercase text-right fw500" order="1">
                                    <v-icon :color="ticketStatus[ticket.status].color" small class="ml-auto">
                                        mdi-circle
                                    </v-icon>
                                    {{ ticketStatus[ticket.status].text }}
                                </v-card-text>
                            </section>
                            <v-chip small class="f9 mx-3 mb-3 text-uppercase" > {{  ticket.concern_category  }}</v-chip>
                            <v-divider />
                            <v-card-text  style="height:40px;" class="overflow-y-hidden ellipsis">
                                {{ ticket.content.length === 0 ? '-' : ticket.content }} 
                            </v-card-text>
                            <v-card-text class="text-right f11">
                                {{$dateFormat.dayDateTime(ticket.created_at)}}
                            </v-card-text>
                        </v-card>
                        
                        <FormPagination 
                            :pageCount="pageCount" 
                            :page="page"
                            :paginate="paginate"
                            @page="(e) => {page = e, getTickets()}" 
                            @paginate="(e) => {paginate = e, getTickets()}"/>
                        
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col v-if="selected" :cols="$vuetify.breakpoint.smAndDown ? '12' : (selected ? '8' : '12')">
                
                <v-card class="custom-border border pa-5">
                    <div class="d-flex justify-end">
                        <v-btn icon small @click="selected = null">
                            <v-icon small>
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </div>
                    <TicketMessages :ticket="selected" @getTickets="getTickets()"/>
                </v-card>
            </v-col>
        </v-row>
        <!-- <v-data-table
            :headers="ticket_tbl"
            :items="tickets"
            :loading="loading"
            hide-default-footer
            class="elevation-1 text-center poppins"
        >
            <template v-slot:item.id="{ item }">
                <v-btn text class="fw400" color="primary" @click="openDialog(item)">
                    EDU-{{item.id}}
                </v-btn>
            </template>
            <template v-slot:item.concern_category="{ item }">
                <section class="text-capitalize">
                    {{ item.concern_category }}
                </section>
            </template>
            <template v-slot:item.status="{ item }">
                <span class="text-uppercase">
                    <v-icon :color="ticketStatus[item.status].color" small class="mr-2">
                        mdi-circle
                    </v-icon>
                    {{ ticketStatus[item.status].text }}
                </span>
            </template>
            <template v-slot:item.created_at="{ item }">
                {{$dateFormat.dayDateTime(item.created_at)}}
            </template>
        </v-data-table>
        <FormPagination 
            :pageCount="pageCount" 
            :page="page"
            :paginate="paginate"
            @page="(e) => { page = e, getTickets() }" 
            @paginate="(e) => { paginate = e, getTickets() }"/>
        <TicketDialog v-if="selectedTicket" :dialog="dialog" :ticket="selectedTicket" @close="closeDialog"/> -->
    </section>
</template>
<script>
import TicketDialog from './TicketDialog.vue';
import { mapActions, mapMutations, mapState } from 'vuex'
import { ticket_tbl } from '../../../constants/tblheaders/ticket';
import { ticketStatus } from '@/constants/colors';
import TicketMessages from './TicketMessages.vue';

export default {
    props: ["tickets", "tab"],
    computed: {
        ...mapState('usr', {
            tickets: (state) => state.tickets,
        }),
        ...mapState({
            errors: (state) => state.errors,
        }),
    },
    data: () => ({
        ticket_tbl,
        dialog: false,
        selectedTicket: null,
        sortBy: 'id',
        sortDesc: true,
        loading: false,
        pageCount: 1,
        paginate: '10',
        page: 1,
        ticketStatus,
        filter: {
            category: '',
            status: ''
        },
        selected: null,
        menu: false,
        category: [ 
            {
                text: "All",
                id: "",
                count: 0
            },
            {
                text: "Payment",
                id: "payment",
                count: 0
            },
            {
                text: "Change of email address",
                id: "change_email",
                count: 0
            },
            {
                text: "Personal Information",
                id: "personal_info",
                count: 0
            },
            {
                text: "Change of Institution/Training Programs",
                id: "change_traning_programs",
                count: 0
            },
            {
                text: "Programs",
                id: "programs",
                count: 0
            }
        ],
    }),
    
    created(){
        this.getTickets()
    },
    methods: {
        ...mapActions('usr', ['getTicketsAction', 'submitTicketAction']),

        openDialog(item){
            this.dialog = true
            this.selectedTicket = item
        },

        closeDialog(){
            this.dialog=false
        },

        getTickets(){
            this.loading = true
            this.getTicketsAction({ category: this.filter.category, status: this.filter.status, page: this.page, paginate: Number(this.paginate)}).then(res => {
                this.page = res.current_page
                this.paginate = String(res.per_page)
                this.pageCount = res.last_page
            }).finally(() => {
                this.loading = false
            })
        }
    },
    watch: {
        tab() {
            this.getTickets()
        }
    },
    components: { TicketDialog, TicketMessages }
}
</script>